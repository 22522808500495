import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import BasePage from "./components/BasePage";
import "./index.scss";
import "./style/app.css";
import configureStore from "./store/configureStore";

const store = configureStore();

render(
  <Provider store={store}>
    <BasePage />
  </Provider>,
  document.getElementById("root")
);
