import React from "react";
import { bool, func } from "prop-types";
import Button from "@cx/ui/Button";
import IconHome from "@cx/ui/Icons/IconHome";
import Navbar from "react-bootstrap/lib/Navbar";
import Nav from "react-bootstrap/lib/Nav";
import NavItem from "react-bootstrap/lib/NavItem";
import config from "../config";

const CLIENT_ENV_NAME = `${config.CLIENT_ENV_NAME}`;
const ENV = `${config.ENV}`;
const CLIENT_ENV_LOCAL = "local";
const CLIENT_ENV_TO_SKIP = "prod";

const Navigation = ({ onHomeClick, loading }) => {
  return (
    <Navbar
      fluid={true}
      staticTop={true}
      className="header-bar__nav-header_branding-bar"
    >
      <Nav bsStyle="pills">
        <NavItem onClick={onHomeClick}>
          <img
            className="header-bar__nav-header_logo2"
            alt="DealerTrack Icon"
            src={require("../images/cox-dt-color-logo.png")}
          />
        </NavItem>
      </Nav>
      <Navbar.Collapse>
        <Nav activeKey={ENV}>
          <NavItem onClick={onHomeClick}>
            <Button htmlId="IconHome" className="btn--icon" aria-label="Home">
              <IconHome />
            </Button>
          </NavItem>
          {CLIENT_ENV_NAME === CLIENT_ENV_LOCAL && (
            <NavItem eventKey={"local"} href="?env=local">
              Local
            </NavItem>
          )}
          {CLIENT_ENV_NAME !== CLIENT_ENV_TO_SKIP && (
            <NavItem eventKey={"devint"} href="?env=devint">
              DevInt
            </NavItem>
          )}
          {CLIENT_ENV_NAME !== CLIENT_ENV_TO_SKIP && (
            <NavItem eventKey={"qa"} href="?env=qa">
              QA
            </NavItem>
          )}
          {CLIENT_ENV_NAME !== CLIENT_ENV_TO_SKIP && (
            <NavItem eventKey={"uat"} href="?env=uat">
              UAT
            </NavItem>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

Navigation.propTypes = {
  loading: bool,
  onHomeClick: func.isRequired
};

export default Navigation;
