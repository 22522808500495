const hostName = window.location.href.split("?")[0];
let clientName = "";
if (hostName.includes("fni-static-np.dealertrack.com")) {
  clientName = "np";
} else if (hostName.includes("localhost")) {
  clientName = "local";
} else {
  clientName = "prod";
}

export function getClientByUrl(clientObject) {
  return clientObject[clientName];
}

export function getClientNameByUrl() {
  return clientName;
}
