import React from "react";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Panel from "react-bootstrap/lib/Panel";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";

const Legal = ({ onLegalAcceptClick, loading, payoffQuoteData }) => {
  return (
    <div>
      <Panel>
        <PageHeader>Payoff Quotes</PageHeader>
        <Card
          htmlId="CardDefault"
          header="Welcome to American Honda Finance Corporation (AHFC) Payoff Quote Portal"
        >
          <div>
            <p>
              <br />
              This page is exclusive for dealer payoff quotes via AHFC. Improper
              use of this site constitutes fraudulent behavior.
            </p>
            <p>
              <br />
              <b>Not a Dealertrack customer?</b>
              <br />
              You can access the dealer payoff quote page{" "}
              <a
                href="#"
                onClick={onLegalAcceptClick}
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
          </div>
        </Card>
        <ButtonToolbar>
          <Button
            htmlId="login-button"
            href="https://ww2.dealertrack.com/payoff/"
            buttonStyle="primary"
          >
            Login via Dealertrack
          </Button>
          <Button
            htmlId="sign-up-button"
            href="https://www.dealertrack.com/public/registrationform/registrationform.aspx"
          >
            Sign Up
          </Button>
        </ButtonToolbar>
        <p className="small margin-top-15-button">
          Sign ups for Dealertrack will entail a review of eligibility,
          registration has a 2-5 day standard turn around timeframe.
        </p>
      </Panel>
    </div>
  );
};

Legal.propTypes = {
  loading: PropTypes.bool,
  onLegalAcceptClick: PropTypes.func.isRequired,
  payoffQuoteData: PropTypes.object
};

export default Legal;
