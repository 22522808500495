import * as types from "./actionTypes";
import { getCurrentISODateTime } from "../utils/dates";
import {
  getToken,
  postSearch,
  postAddressValidation
} from "../api/payoffQuoteApi";

export function loading() {
  return { type: types.LOADING };
}

export function loadingCompleted() {
  return { type: types.LOADING_COMPLETE };
}

export function legalAcceptance(values) {
  return function(dispatch) {
    dispatch({
      type: types.LEGAL_ACCEPT,
      dateModified: getCurrentISODateTime(),
      payload: values
    });
  };
}

export function verifyCaptcha(captchaToken) {
  return function(dispatch) {
    dispatch({
      type: types.VERIFY_CAPTCHA,
      dateModified: getCurrentISODateTime(),
      captchaToken
    });
    if (captchaToken) {
      getToken(captchaToken)
        .then(res => {
          dispatch({
            type: types.VERIFY_CAPTCHA_SUCCESS,
            dateModified: getCurrentISODateTime(),
            jwtToken: res.data.access_token
          });
        })
        .catch(err => {
          dispatch({
            type: types.VERIFY_CAPTCHA_FAILURE,
            dateModified: getCurrentISODateTime(),
            captchaToken
          });
        });
    }
  };
}

export function updateSearch(settings, fieldName, value, isValid) {
  return {
    type: types.UPDATE_SEARCH,
    dateModified: getCurrentISODateTime(),
    settings,
    fieldName,
    value,
    isValid
  };
}

export function validateAndSearch(values) {
  return function(dispatch) {
    // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
    // in this case at this point we could call a service that would persist the fuel savings
    if (values.jwtToken) {
      if (values.inquiryData.financeType === "Lease") {
        dispatch(loading());
        const addressRequestJSON = {};
        const addressJSON = {};
        const addressLines = [];

        addressLines.push(values.inquiryData.addressStreet);
        addressJSON.city = values.inquiryData.addressCity;
        addressJSON.state = values.inquiryData.addressState;
        addressJSON.postalCode = values.inquiryData.addressPostal;
        addressJSON.country = "US";
        addressJSON.addressLines = addressLines;
        addressRequestJSON.address = addressJSON;

        postAddressValidation(
          values.jwtToken,
          values.dealerId,
          addressRequestJSON
        )
          .then(res => {
            dispatch({
              type: types.SHOW_ADDRESS_VALIDATOR,
              dateModified: getCurrentISODateTime(),
              addressValidationResult: res.data
            });
            dispatch(loadingCompleted());
          })
          .catch(err => {
            let errorData = {};
            if (err.response && err.response.data) {
              errorData = err.response.data;
              if (errorData.code && errorData.code !== "other") {
                if (errorData.message) {
                  errorData.errorMessage =
                    "Error. Turn-in address " + errorData.message;
                }
              }
            }
            if (!errorData.errorMessage) {
              errorData.errorMessage =
                "Error in validating address. Please try again. If this error persists contact Dealertrack support.";
            }

            dispatch({
              type: types.ADDRESS_VALIDATOR_FAILED,
              dateModified: getCurrentISODateTime(),
              inquiryResult: errorData
            });
            dispatch(loadingCompleted());
          });
      } else {
        performSearch(dispatch, values);
      }
    }
  };
}

function performSearch(dispatch, values) {
  dispatch(loading());

  const requestJSON = {};
  requestJSON.partnerId = values.inquiryData.partner;
  requestJSON.financeMethod = values.inquiryData.financeType;
  requestJSON.searchKey = values.inquiryData.searchType;
  if (requestJSON.searchKey === "VIN") {
    requestJSON.searchValue = values.inquiryData.vin;
  } else if (requestJSON.searchKey === "ACC") {
    requestJSON.searchValue = values.inquiryData.acc;
  }
  requestJSON.ssnLast4 = values.inquiryData.ssnLast4;
  if (values.inquiryData.financeType === "Lease") {
    requestJSON.turnInAddress = {};
    requestJSON.turnInAddress.name = values.inquiryData.dealerName;
    requestJSON.turnInAddress.addressLine =
      values.addressValidationResult.address.addressLines[0];
    requestJSON.turnInAddress.city =
      values.addressValidationResult.address.city;
    requestJSON.turnInAddress.state =
      values.addressValidationResult.address.state;
    requestJSON.turnInAddress.county =
      values.addressValidationResult.address.county;
    requestJSON.turnInAddress.postalCode =
      values.addressValidationResult.address.postalCode;
    if (values.inquiryData.turnInPolice) {
      if (values.inquiryData.turnInPolice === "Y") {
        requestJSON.turnInAddress.policeJurisdiction = true;
      } else {
        requestJSON.turnInAddress.policeJurisdiction = false;
      }
    }
    if (values.inquiryData.turnInUnincorporated) {
      if (values.inquiryData.turnInUnincorporated === "Y") {
        requestJSON.turnInAddress.unincorporatedArea = true;
      } else {
        requestJSON.turnInAddress.unincorporatedArea = false;
      }
    }
  }
  postSearch(values.jwtToken, values.dealerId, requestJSON)
    .then(res => {
      if (
        res.data.payoffQuoteResponse &&
        res.data.payoffQuoteResponse.length === 1 &&
        res.data.payoffQuoteResponse[0]
      ) {
        if (res.data.payoffQuoteResponse[0].payoffMsg) {
          const errorData = res.data;
          errorData.errorMessage = res.data.payoffQuoteResponse[0].payoffMsg;
          dispatch({
            type: types.SEARCH_FAIL,
            dateModified: getCurrentISODateTime(),
            inquiryResult: errorData
          });
        } else {
          dispatch({
            type: types.SEARCH_SUCCESS,
            dateModified: getCurrentISODateTime(),
            inquiryResult: res.data
          });
        }
      } else {
        const errorData = res.data;
        if (errorData && errorData.message && errorData.code !== "other") {
          errorData.errorMessage = errorData.message;
        } else {
          errorData.errorMessage =
            "Error in getting the payoff quote. Please try again. If this error persists contact Dealertrack support.";
        }
        dispatch({
          type: types.SEARCH_FAIL,
          dateModified: getCurrentISODateTime(),
          inquiryResult: errorData
        });
      }

      dispatch(loadingCompleted());
    })
    .catch(err => {
      let errorData = {};
      if (err.response && err.response.data) {
        errorData = err.response.data;
      }
      if (
        errorData &&
        errorData.code &&
        errorData.code !== "other" &&
        errorData.message
      ) {
        errorData.errorMessage =
          errorData.message +
          " Please try again. If this error persists contact Dealertrack support.";
      } else {
        errorData = {};
        errorData.errorMessage =
          "Error in getting the payoff quote. Please try again. If this error persists contact Dealertrack support.";
      }
      dispatch({
        type: types.SEARCH_FAIL,
        dateModified: getCurrentISODateTime(),
        inquiryResult: errorData
      });
      dispatch(loadingCompleted());
    });
}

export function home(values) {
  return function(dispatch) {
    dispatch({
      type: types.HOME,
      dateModified: getCurrentISODateTime(),
      payload: values
    });
  };
}

export function addressValidatorClose(values) {
  return function(dispatch) {
    dispatch({
      type: types.ADDRESS_VALIDATOR_CLOSE,
      dateModified: getCurrentISODateTime(),
      payload: values
    });
  };
}

export function addressValidatorAccept(values) {
  return function(dispatch) {
    performSearch(dispatch, values);
  };
}
