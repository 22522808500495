import { getClientByUrl } from "./getClientByUrl";

export default {
  BASE_API_URL: "https://fni-api.dealertrack.com/sfni/payoff-quotes/v2",
  CAPTCHA_SITE_KEY: "6LeXaMEUAAAAABRSZnDdzhp2cgpTeeKj0EMTspUl",
  LENDER_ID: "AHC",
  DEALER_DTC_ID: 902986,
  CLIENT: getClientByUrl({
    local: {
      HOST_NAME:
        "https://fni-static-np.dealertrack.com/apps/fni-payoff-quote-app/versions/1.0.0/index.html"
    },
    np: {
      HOST_NAME:
        "https://fni-static-np.dealertrack.com/apps/fni-payoff-quote-app/versions/1.0.0/index.html"
    },
    prod: {
      HOST_NAME: "https://payoffahfc.dealertrack.com"
    }
  })
};
