export default class ObjectUtil {
  static eliminateValidFieldProperties(object) {
    const allKeys = Object.keys(object);
    allKeys.forEach(k => {
      if (object[k] === true) {
        delete object[k];
      }
    });
    return object;
  }
}
