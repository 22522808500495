import axios from "axios";
import config from "../config";
import { uuidv4 } from "../utils/common";

export const getToken = function(captchaToken) {
  return axios
    .create({
      baseURL: `${config.BASE_API_URL}`,
      responseType: "json",
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + captchaToken,
        "X-CoxAuto-Correlation-Id": uuidv4()
      }
    })
    .get("/jwt/token");
};

export const postSearch = function(jwtToken, dealerId, body) {
  const requestObj = axios.create({
    baseURL: `${config.BASE_API_URL}`,
    responseType: "json",
    headers: {
      "Cache-Control": "no-cache",
      Authorization: "Bearer " + jwtToken,
      "dealer-id": dealerId,
      "X-CoxAuto-Correlation-Id": uuidv4()
    }
  });
  if (`${config.ENV}` === "local") {
    return requestObj.get("/jwt/quotes", body);
  } else {
    return requestObj.post("/jwt/quotes", body);
  }
};

export const postAddressValidation = function(jwtToken, dealerId, body) {
  const requestObj = axios.create({
    baseURL: `${config.BASE_API_URL}`,
    responseType: "json",
    headers: {
      "Cache-Control": "no-cache",
      Authorization: "Bearer " + jwtToken,
      "dealer-id": dealerId,
      "X-CoxAuto-Correlation-Id": uuidv4()
    }
  });
  if (`${config.ENV}` === "local") {
    return requestObj.get("/jwt/address-validations", body);
  } else {
    return requestObj.post("/jwt/address-validations", body);
  }
};
