import { getClientByUrl } from "./getClientByUrl";

export default {
  BASE_API_URL: "https://fni-api-np.dealertrack.com/sfni/qa1/payoff-quotes/v2",
  CAPTCHA_SITE_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  LENDER_ID: "AHC",
  DEALER_DTC_ID: 104334,
  CLIENT: getClientByUrl({
    local: {
      HOST_NAME: "http://localhost:3000"
    },
    np: {
      HOST_NAME:
        "https://fni-static-np.dealertrack.com/apps/fni-payoff-quote-app/versions/1.0.0/index.html"
    },
    prod: {
      HOST_NAME: "prod not supported here, this is just placeholder"
    }
  })
};
