import React from "react";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Inquiry from "./Inquiry";
import Legal from "./Legal";
import Detail from "./Detail";
import Row from "@cx/ui/Row";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { object, bool } from "prop-types";
import * as payoffQuoteActions from "../actions/payoffQuoteActions";
import Navigation from "./Navigation";

class BasePage extends React.Component {
  static propTypes = {
    actions: object.isRequired,
    payoffQuote: object.isRequired
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  legalAcceptance = () => {
    this.props.actions.legalAcceptance(this.props.payoffQuote);
  };

  addressValidatorClose = () => {
    this.props.actions.addressValidatorClose(this.props.payoffQuote);
  };

  addressValidatorAccept = () => {
    this.props.actions.addressValidatorAccept(this.props.payoffQuote);
  };

  search = () => {
    this.props.actions.validateAndSearch(this.props.payoffQuote);
  };

  home = () => {
    this.props.actions.home(this.props.payoffQuote);
  };

  verifyCaptcha = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.props.actions.verifyCaptcha(recaptchaToken);
  };

  formIsValid = () => {
    const errors = this.props.payoffQuote.errors;
    const inquiryData = this.props.payoffQuote.inquiryData;
    if (errors && errors.ssnLast4 === false) {
      return false;
    }
    let isRequiredPasssed =
      inquiryData.ssnLast4 && inquiryData.agreement && inquiryData.agreement[0];
    if (inquiryData.financeType === "Lease") {
      if (
        errors &&
        (errors.addressPostal === false || errors.addressStreet === false)
      ) {
        return false;
      }
      isRequiredPasssed =
        isRequiredPasssed &&
        inquiryData.dealerName &&
        inquiryData.addressStreet &&
        inquiryData.addressCity &&
        inquiryData.addressPostal &&
        inquiryData.addressState &&
        inquiryData.turnInAgreement &&
        inquiryData.turnInAgreement[0];

      if (inquiryData.addressState === "AL") {
        isRequiredPasssed = isRequiredPasssed && inquiryData.turnInPolice;
      }
      if (["AL", "AZ", "CA", "MS", "OH"].includes(inquiryData.addressState)) {
        isRequiredPasssed =
          isRequiredPasssed && inquiryData.turnInUnincorporated;
      }
    }

    if (inquiryData.searchType === "VIN") {
      if (errors && errors.vin === false) {
        return false;
      }
      isRequiredPasssed = isRequiredPasssed && inquiryData.vin;
    } else if (inquiryData.searchType === "ACC") {
      if (errors && errors.acc === false) {
        return false;
      }
      isRequiredPasssed = isRequiredPasssed && inquiryData.acc;
    }
    return (
      isRequiredPasssed &&
      this.props.payoffQuote.captchaToken &&
      this.props.payoffQuote.jwtToken
    );
  };

  onChange = (e, isValid, domEvent) => {
    if (!e.target.name)
      throw new Error("The name prop is required on all form inputs");

    this.props.actions.updateSearch(
      this.props.payoffQuote,
      e.target.name,
      e.target.value,
      isValid
    );
  };

  getAddressError = (label, value) => {
    const priceRegex = /(p\.?\s?o\.?|post.*\s)\s?box/;
    return value && priceRegex.test(value.toLowerCase())
      ? "PO Box is not allowed for the turn in address."
      : "";
  };

  render() {
    const isSearchDone = this.props.payoffQuote.isSearchDone;
    const legalsAccepted = this.props.payoffQuote.legalsAccepted;
    const payoffQuoteData = this.props.payoffQuote;
    const loading = this.props.loading;
    let component;
    if (isSearchDone) {
      component = (
        <Detail
          onDetailClose={this.home}
          loading={loading}
          payoffQuoteData={payoffQuoteData}
        />
      );
    } else if (legalsAccepted) {
      component = (
        <Inquiry
          formIsValid={this.formIsValid}
          verifyCallback={this.verifyCaptcha}
          onSearchClick={this.search}
          onChange={this.onChange}
          getAddressError={this.getAddressError}
          onAddressValidatorClose={this.addressValidatorClose}
          onAddressValidatorAccept={this.addressValidatorAccept}
          loading={loading}
          payoffQuoteData={payoffQuoteData}
        />
      );
    } else {
      component = (
        <Legal
          onLegalAcceptClick={this.legalAcceptance}
          loading={loading}
          payoffQuoteData={payoffQuoteData}
        />
      );
    }
    return (
      <div>
        <Navigation onHomeClick={this.home} />
        <Grid htmlId="BaseGrid">
          <Row className="show-grid">
            <Col xs={12} md={10} lg={8}>
              {component}
            </Col>
          </Row>
          <Row className="show-grid hidden-print">
            <Col xs={12} md={10} lg={8}>
              <div className="pull-right">
                <div className="footer">
                  {" "}
                  © 2001-2020 Dealertrack Technologies, Inc. All rights
                  reserved.
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

// Tell Redux exactly what state slice we want from the global state
// Note that state.fuelSavingsAppState below is the name of
// the reducer function in /reducers/fuelSavings.js
function mapStateToProps(state) {
  return {
    payoffQuote: state.payoffQuote,
    loading: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // bindActionCreators will wrap all actions in payoffQuoteActions
    // in a dispatch call so they're all available to the component
    // above on this.props.payoffQuoteActions.
    actions: bindActionCreators(payoffQuoteActions, dispatch)
  };
}

BasePage.propTypes = {
  loading: bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasePage);
