import React from "react";
import PropTypes from "prop-types";
import Panel from "react-bootstrap/lib/Panel";
import PanelTitle from "react-bootstrap/lib/PanelTitle";
import Form from "react-bootstrap/lib/Form";
import Card from "@cx/ui/Card";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import VinInput from "@cx/ui/VinInput";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import SelectInput from "@cx/ui/SelectInput";
import PageHeader from "react-bootstrap/lib/PageHeader";
import CheckBoxList from "@cx/ui/CheckBoxList";
import SubmitButton from "@cx/ui/SubmitButton";
import RadioButtonList from "@cx/ui/RadioButtonList";
import StateSelectInput from "@cx/ui/StateSelectInput";
import PostalCodeInput from "@cx/ui/PostalCodeInput";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@cx/ui/Alert";
import config from "../config";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";

const incorporatedStates = ["AL", "AZ", "CA", "MS", "OH"];
const CAPTCHA_SITE_KEY_VAL = `${config.CAPTCHA_SITE_KEY}`;
const LENDER_ID_VAL = `${config.LENDER_ID}`;

const Inquiry = ({
  formIsValid,
  verifyCallback,
  onSearchClick,
  onChange,
  loading,
  payoffQuoteData,
  onAddressValidatorClose,
  onAddressValidatorAccept,
  getAddressError
}) => {
  return (
    <div>
      <Panel>
        <PageHeader>Payoff Quotes</PageHeader>
        <Panel>
          <PanelTitle>Request a Payoff Quote</PanelTitle>
          <Form autoComplete="off">
            {payoffQuoteData.inquiryResultError === true && (
              <ScrollIntoViewIfNeeded>
                <Row>
                  <Col sm={12} md={12} lg={12} className={"top-error-message"}>
                    <Alert htmlId="dangerAlert" type="danger">
                      {payoffQuoteData.inquiryResult.errorMessage}
                    </Alert>
                  </Col>
                </Row>
              </ScrollIntoViewIfNeeded>
            )}
            <Row>
              <Col sm={12} md={6} lg={6}>
                <SelectInput
                  htmlId="id_partner"
                  name="partner"
                  label="Lenders"
                  displayPlaceholder={false}
                  onChange={onChange}
                  required
                  value={payoffQuoteData.inquiryData.partner}
                  options={[
                    {
                      value: LENDER_ID_VAL,
                      label: "American Honda Finance Corporation"
                    }
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Alert htmlId="infoAlert" type="info">
                  AHFC lease payoff quotes are only available to authorized
                  Honda and Acura dealers through Honda&apos;s dealer portal.
                  AHFC off-lease vehicles must be returned to authorized Honda
                  or Acura dealers in accordance with the consumer lease
                  agreement.
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <RadioButtonList
                  inline
                  htmlId="RadioButtonListHorizontalInline"
                  name="financeType"
                  label="Product"
                  required
                  onChange={onChange}
                  value={payoffQuoteData.inquiryData.financeType}
                  options={[
                    { value: "Retail", label: "Retail or Balloon" },
                    { value: "Lease", label: "Lease", disabled: true }
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <SelectInput
                  htmlId="id_customer"
                  name="searchType"
                  label="Customer Information"
                  displayPlaceholder={false}
                  onChange={onChange}
                  required
                  value={payoffQuoteData.inquiryData.searchType}
                  options={[
                    {
                      value: "VIN",
                      label: "Full VIN"
                    },
                    { value: "ACC", label: "Full Account Number" }
                  ]}
                />
                {payoffQuoteData.inquiryData.searchType === "VIN" && (
                  <VinInput
                    htmlId="VinInput"
                    label="Full VIN"
                    maxLength={17}
                    name="vin"
                    required
                    onChange={onChange}
                    value={payoffQuoteData.inquiryData.vin}
                    autocomplete="vin"
                  />
                )}
                {payoffQuoteData.inquiryData.searchType === "ACC" && (
                  <TextInput
                    htmlId="AccInput"
                    label="Full Account Number"
                    minLength={1}
                    maxLength={14}
                    name="acc"
                    required
                    onChange={onChange}
                    value={payoffQuoteData.inquiryData.acc}
                    autocomplete="off"
                  />
                )}
                <NumericInput
                  htmlId="NumericInputDefault"
                  label="SSN"
                  inputPrefix="Last four"
                  name="ssnLast4"
                  required
                  onChange={onChange}
                  value={payoffQuoteData.inquiryData.ssnLast4}
                  maxLength={4}
                  minLength={4}
                  autocomplete="off"
                />
              </Col>
              {payoffQuoteData.inquiryData.financeType === "Lease" && (
                <Col sm={12} md={6} lg={6}>
                  <Card htmlId="TurnInAddress" header="Turn in Address">
                    <Row>
                      <Col md={6}>
                        <TextInput
                          label="Dealership Name"
                          name="dealerName"
                          maxLength={60}
                          htmlId="dealerName"
                          required
                          onChange={onChange}
                          value={payoffQuoteData.inquiryData.dealerName}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          label="Address"
                          name="addressStreet"
                          maxLength={60}
                          htmlId="customerStreet"
                          required
                          onChange={onChange}
                          value={payoffQuoteData.inquiryData.addressStreet}
                          getError={getAddressError}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <TextInput
                          label="City"
                          name="addressCity"
                          maxLength={30}
                          htmlId="customerCity"
                          required
                          onChange={onChange}
                          value={payoffQuoteData.inquiryData.addressCity}
                        />
                      </Col>
                      <Col md={6}>
                        <StateSelectInput
                          htmlId="customerStateSelectInput"
                          name="addressState"
                          value={payoffQuoteData.inquiryData.addressState}
                          country="All"
                          required
                          onChange={onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <PostalCodeInput
                          htmlId="customerPostalCodeInput"
                          name="addressPostal"
                          label="Zip Code"
                          required
                          onChange={onChange}
                          value={payoffQuoteData.inquiryData.addressPostal}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {payoffQuoteData.inquiryData.addressState === "AL" && (
                        <Col md={6}>
                          <RadioButtonList
                            inline
                            htmlId="RadioButtonListHorizontalInline"
                            name="turnInPolice"
                            label="Address in police jurisdiction?"
                            required
                            onChange={onChange}
                            value={payoffQuoteData.inquiryData.turnInPolice}
                            options={[
                              { value: "Y", label: "Yes" },
                              { value: "N", label: "No" }
                            ]}
                          />
                        </Col>
                      )}
                      {incorporatedStates.includes(
                        payoffQuoteData.inquiryData.addressState
                      ) && (
                        <Col md={6}>
                          <RadioButtonList
                            inline
                            htmlId="RadioButtonListHorizontalInline"
                            name="turnInUnincorporated"
                            label="Address in an unincorporated area?"
                            required
                            onChange={onChange}
                            value={
                              payoffQuoteData.inquiryData.turnInUnincorporated
                            }
                            options={[
                              { value: "Y", label: "Yes" },
                              { value: "N", label: "No" }
                            ]}
                          />
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Card htmlId="CardDefault" bg="light" header="Agreement">
                  <div>
                    Lender cannot disclose any customer&apos;s payoff
                    information without the customer&apos;s consent. When I, the
                    dealer, click on the "Customer Quote" button, I acknowledge
                    and agree that I have received the customer&apos;s consent
                    to obtain this payoff information from Lender.{" "}
                    <span className={"agreement-required"}>*</span>
                  </div>
                  <CheckBoxList
                    htmlId="CheckBoxListDefault"
                    name="agreement"
                    onChange={onChange}
                    values={payoffQuoteData.inquiryData.agreement}
                    displayLabel={false}
                    required
                    options={[
                      {
                        value: "1",
                        label:
                          "I acknowledge and agree that I have received the customer's consent to obtain this payoff information from Lender."
                      }
                    ]}
                  />
                  {payoffQuoteData.inquiryData.financeType === "Lease" && (
                    <CheckBoxList
                      htmlId="CheckBoxListDefault-turnInAgreement"
                      name="turnInAgreement"
                      onChange={onChange}
                      values={payoffQuoteData.inquiryData.turnInAgreement}
                      displayLabel={false}
                      required
                      options={[
                        {
                          value: "1",
                          label:
                            "I acknowledge that the turn-in address indicated is the true turn-in address of the vehicle."
                        }
                      ]}
                    />
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <ReCAPTCHA
                  sitekey={CAPTCHA_SITE_KEY_VAL}
                  onChange={verifyCallback}
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                {payoffQuoteData.captchaFailed === true && (
                  <Alert htmlId="dangerAlert" type="danger">
                    Error. System can not verify your identity. Please contact
                    support.
                  </Alert>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <SubmitButton
                  htmlId="SubmitButtonDefault"
                  isLoading={loading}
                  className="margin-top-15-button"
                  onClick={onSearchClick}
                  disabled={loading || !formIsValid()}
                >
                  Customer Quote
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Panel>
      <ModalDialog
        htmlId="AddressValidatorModel"
        show={payoffQuoteData.showAddressValidatorModel}
        header={<ModalDialog.Title>Address Correction Found</ModalDialog.Title>}
        footer={
          <div className="pull-left">
            <SubmitButton
              htmlId="acceptbutton"
              isLoading={loading}
              onClick={onAddressValidatorAccept}
              text="Accept Button"
              buttonStyle="primary"
              disabled={loading}
            >
              Accept
            </SubmitButton>
            <Button
              htmlId="closebutton"
              onClick={onAddressValidatorClose}
              text="Close Button"
            >
              Cancel
            </Button>
          </div>
        }
        onHide={onAddressValidatorClose}
      >
        <div>
          <p>Please verify and accept the corrected turn-in address.</p>
          <Card htmlId="CardWithHeader1" header="Entered address:">
            <address>
              {payoffQuoteData.inquiryData.dealerName}
              <br />
              {payoffQuoteData.inquiryData.addressStreet}
              <br />
              {payoffQuoteData.inquiryData.addressCity}
              {", "}
              {payoffQuoteData.inquiryData.addressState}
              {", "}
              {payoffQuoteData.inquiryData.addressPostal}
              <br />
            </address>
          </Card>
          <Card htmlId="CardWithHeader2" header="Corrected address:">
            <address>
              {payoffQuoteData.inquiryData.dealerName}
              <br />
              {payoffQuoteData.addressValidationResult.address.addressLines[0]}
              <br />
              {payoffQuoteData.addressValidationResult.address.city}
              {", "}
              {payoffQuoteData.addressValidationResult.address.county}
              {", "}
              {payoffQuoteData.addressValidationResult.address.state}
              {", "}
              {payoffQuoteData.addressValidationResult.address.postalCode}
              <br />
            </address>
          </Card>
        </div>
      </ModalDialog>
    </div>
  );
};

Inquiry.propTypes = {
  formIsValid: PropTypes.func.isRequired,
  getAddressError: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onAddressValidatorAccept: PropTypes.func.isRequired,
  onAddressValidatorClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  payoffQuoteData: PropTypes.object,
  verifyCallback: PropTypes.func.isRequired
};

export default Inquiry;
