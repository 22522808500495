// Legal Page
export const LEGAL_ACCEPT = "LEGAL_ACCEPT";
export const HOME = "HOME";
export const VERIFY_CAPTCHA = "VERIFY_CAPTCHA";
export const VERIFY_CAPTCHA_SUCCESS = "VERIFY_CAPTCHA_SUCCESS";
export const VERIFY_CAPTCHA_FAILURE = "VERIFY_CAPTCHA_FAILURE";

// Search
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const SEARCH = "SEARCH";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

// Address validator
export const SHOW_ADDRESS_VALIDATOR = "SHOW_ADDRESS_VALIDATOR";
export const ADDRESS_VALIDATOR_CLOSE = "ADDRESS_VALIDATOR_CLOSE";
export const ADDRESS_VALIDATOR_ACCEPT = "ADDRESS_VALIDATOR_ACCEPT";
export const ADDRESS_VALIDATOR_FAILED = "ADDRESS_VALIDATOR_FAILED";

// Loading
export const LOADING = "LOADING";
export const LOADING_COMPLETE = "LOADING_COMPLETE";
