import prodConfig from "./config.prod";
import qaConfig from "./config.qa";
import uatConfig from "./config.uat";
import devIntConfig from "./config.devInt";
import localConfig from "./config.local";
import { getQueryParamByKey } from "../utils/URLUtils";
import { QP_ENV } from "../urlConstants";
import { getClientNameByUrl } from "./getClientByUrl";

export const ENV_LOCAL = "local";
export const ENV_DEVINT = "devint";
export const ENV_QA = "qa";
export const ENV_UAT = "uat";
export const ENV_PROD = "pqwrod";

// Warning: checking query param here doesn't work with serverside rendering
let config;
let env = getQueryParamByKey(QP_ENV);
const clientName = getClientNameByUrl();

if (!env) {
  if (clientName === "np") {
    env = ENV_DEVINT;
  } else if (clientName === "local") {
    env = ENV_LOCAL;
  }
}
if (env.toLowerCase() === ENV_QA.toLowerCase()) {
  config = qaConfig;
} else if (env.toLowerCase() === ENV_UAT.toLowerCase()) {
  config = uatConfig;
} else if (env.toLowerCase() === ENV_DEVINT.toLowerCase()) {
  config = devIntConfig;
} else if (env.toLowerCase() === ENV_LOCAL.toLowerCase()) {
  config = localConfig;
} else {
  if (env !== ENV_PROD) {
    // setStateQueryParam("env", ENV_PROD);
  }
  config = prodConfig;
  env = ENV_PROD;
}
config.ENV = env.toLowerCase();
config.CLIENT_ENV_NAME = clientName;

export default config;
