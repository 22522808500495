import {
  UPDATE_SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  HOME,
  LEGAL_ACCEPT,
  VERIFY_CAPTCHA,
  VERIFY_CAPTCHA_SUCCESS,
  VERIFY_CAPTCHA_FAILURE,
  ADDRESS_VALIDATOR_CLOSE,
  ADDRESS_VALIDATOR_ACCEPT,
  SHOW_ADDRESS_VALIDATOR,
  ADDRESS_VALIDATOR_FAILED
} from "../actions/actionTypes";
import initialState from "./initialState";
import ObjectUtil from "../utils/ObjectUtil";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// We're using Object spread to create a copy of current state
// and update values on the copy.
export default function payoffQuoteReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case VERIFY_CAPTCHA:
      newState = {
        ...state,
        dateModified: action.dateModified,
        captchaToken: action.captchaToken
      };
      return newState;

    case VERIFY_CAPTCHA_SUCCESS:
      newState = {
        ...state,
        dateModified: action.dateModified,
        jwtToken: action.jwtToken
      };
      return newState;

    case VERIFY_CAPTCHA_FAILURE:
      newState = {
        ...state,
        dateModified: action.dateModified,
        captchaFailed: true
      };
      return newState;

    case LEGAL_ACCEPT:
      newState = {
        ...state,
        dateModified: action.dateModified,
        legalsAccepted: true,
        isSearchDone: false
      };
      return newState;

    case UPDATE_SEARCH:
      return updateSearch(state, action);

    case SEARCH_FAIL:
      newState = {
        ...state,
        dateModified: action.dateModified,
        inquiryResult: action.inquiryResult,
        isSearchDone: false,
        showAddressValidatorModel: false,
        inquiryResultError: true
      };
      return newState;

    case SEARCH_SUCCESS:
      newState = {
        ...state,
        dateModified: action.dateModified,
        inquiryResult: action.inquiryResult,
        showAddressValidatorModel: false,
        isSearchDone: true
      };
      return newState;

    case SHOW_ADDRESS_VALIDATOR:
      newState = {
        ...state,
        dateModified: action.dateModified,
        addressValidationResult: action.addressValidationResult,
        showAddressValidatorModel: true
      };
      return newState;

    case ADDRESS_VALIDATOR_CLOSE:
      newState = {
        ...state,
        dateModified: action.dateModified,
        showAddressValidatorModel: false
      };
      return newState;

    case ADDRESS_VALIDATOR_ACCEPT:
      newState = {
        ...state,
        dateModified: action.dateModified,
        showAddressValidatorModel: false
      };
      return newState;

    case ADDRESS_VALIDATOR_FAILED:
      newState = {
        ...state,
        dateModified: action.dateModified,
        showAddressValidatorModel: false,
        isSearchDone: false,
        inquiryResult: action.inquiryResult,
        inquiryResultError: true
      };
      return newState;

    case HOME:
      newState = initialState;
      return newState;

    default:
      return state;
  }
}

function updateSearch(state, action) {
  const newErrors = ObjectUtil.eliminateValidFieldProperties({
    ...state.errors,
    [action.fieldName]: action.isValid
  });
  const newState = {
    ...state,
    dateModified: action.dateModified,
    isSearchDone: false,
    inquiryResultError: false,
    inquiryData: {
      ...state.inquiryData,
      [action.fieldName]: action.value
    },
    errors: newErrors
  };
  return newState;
}
