import React from "react";
import PropTypes from "prop-types";
import { currencyFormat } from "../../utils/common";
import { getUSFormattedDate } from "../../utils/dates";

const ShowNonEmptyTableRow = ({ label, data, type = "value" }) => {
  if (data) {
    if (type === "currency") {
      return (
        <tr>
          <th scope="row" width="30%">
            {label}
          </th>
          <td>{currencyFormat(data)}</td>
        </tr>
      );
    } else if (type === "date") {
      return (
        <tr>
          <th scope="row" width="30%">
            {label}
          </th>
          <td>{getUSFormattedDate(data)}</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <th scope="row" width="30%">
            {label}
          </th>
          <td>{data}</td>
        </tr>
      );
    }
  } else {
    return null;
  }
};

ShowNonEmptyTableRow.propTypes = {
  data: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string
};

export default ShowNonEmptyTableRow;
