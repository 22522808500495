// See tests for desired format.
export function getFormattedDateTime(dateStr = new Date()) {
  const date = new Date(dateStr);
  if (date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  } else {
    return dateStr;
  }
}

export function getUSFormattedDate(dateStr = "") {
  const date = new Date(dateStr.replace(/-/g, "/"));
  if (date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  } else {
    return dateStr;
  }
}

export function padLeadingZero(value) {
  return value > 9 ? value : "0" + value;
}

export function getCurrentDate(separator = "/") {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${padLeadingZero(month)}${separator}${padLeadingZero(
    date
  )}${separator}${year}`;
}

export function getCurrentISODateTime() {
  return new Date().toISOString();
}
