import React from "react";
import Panel from "react-bootstrap/lib/Panel";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Table from "react-bootstrap/lib/Table";
import SubmitButton from "@cx/ui/SubmitButton";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import IconFile from "@cx/ui/Icons/IconFile";
import ShowNonEmptyTableRow from "./reusable/ShowNonEmptyTableRow";
import PayoffInstructionsRetail from "./PayoffInstructionsRetail";
import PayoffInstructionsLease from "./PayoffInstructionsLease";

const Detail = ({ onDetailClose, loading, payoffQuoteData }) => {
  let payoffInstComponent;
  let payoffDocComponent;
  if (payoffQuoteData.inquiryData.financeType === "Lease") {
    payoffInstComponent = (
      <PayoffInstructionsLease payoffQuoteData={payoffQuoteData} />
    );
    payoffDocComponent = (
      <div className="pull-right">
        <Button
          href={require("../images/Honda_Odometer_Disclosure.pdf")}
          download
          htmlId="payoffDocs"
          buttonStyle="link"
          target="_blank"
        >
          <IconFile className="payoff-doc-icon" />
          View Payoff Document
        </Button>
      </div>
    );
  } else {
    payoffInstComponent = (
      <PayoffInstructionsRetail payoffQuoteData={payoffQuoteData} />
    );
  }

  return (
    <div>
      <Panel>
        <PageHeader className={"hidden-print"}>Payoff Quotes</PageHeader>
        <Tabs htmlId="TabsDefault" className={"hidden-print"}>
          <Tab label="Details">
            {" "}
            <Row className={"hidden-print"}>
              <Col>{payoffDocComponent}</Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <div className="verify-panel-table">
                      <h5>Title Information</h5>
                      <Table>
                        <tbody>
                          <ShowNonEmptyTableRow
                            label={"VIN"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].vehicleInfo.vin
                            }
                          />
                          <ShowNonEmptyTableRow
                            label={"Year"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].vehicleInfo.year
                            }
                          />
                          <ShowNonEmptyTableRow
                            label={"Make"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].vehicleInfo.make
                            }
                          />
                          <ShowNonEmptyTableRow
                            label={"Model"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].vehicleInfo.model
                            }
                          />
                          <ShowNonEmptyTableRow
                            label={"Lien Holder"}
                            data={"American Honda Finance Corporation"}
                          />
                          <ShowNonEmptyTableRow
                            label={"Account Number"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].customerInfo.account
                            }
                          />
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <div>
                      <h5>Customer Information</h5>
                      <Table>
                        <tbody>
                          <ShowNonEmptyTableRow
                            label={"First Name"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].customerInfo.firstName
                            }
                          />
                          <ShowNonEmptyTableRow
                            label={"Last Name"}
                            data={
                              payoffQuoteData.inquiryResult
                                .payoffQuoteResponse[0].customerInfo.lastName
                            }
                          />
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="verify-panel-table">
                  <h5>Payment Information</h5>
                  <Table>
                    <tbody>
                      <ShowNonEmptyTableRow
                        label={"Product"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.financeMethod
                        }
                      />
                      <ShowNonEmptyTableRow
                        label={"Net Payoff Amount"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.payoffAmount
                        }
                        type={"currency"}
                      />
                      <ShowNonEmptyTableRow
                        label={"Good Until Date"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.goodUntilDate
                        }
                        type={"date"}
                      />
                      <ShowNonEmptyTableRow
                        label={"Term In Months"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.originalTermCount
                        }
                      />
                      <ShowNonEmptyTableRow
                        label={"Months Left"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.remainingTermCount
                        }
                      />
                      <ShowNonEmptyTableRow
                        label={"Next Payment Date"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.nextPaymentDate
                        }
                        type={"date"}
                      />
                      <ShowNonEmptyTableRow
                        label={"Maturity Date"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.maturityDate
                        }
                        type={"date"}
                      />
                      <ShowNonEmptyTableRow
                        label={"Monthly Payment"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .finance.monthlyPaymentAmount
                        }
                        type={"currency"}
                      />
                      {payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                        .finance.dollarDaysRatio && (
                        <ShowNonEmptyTableRow
                          label={"Dollar Day Rate"}
                          data={
                            "$" +
                            payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                              .finance.dollarDaysRatio
                          }
                        />
                      )}
                      <ShowNonEmptyTableRow
                        label={"Lender Reference #"}
                        data={
                          payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                            .valuedCustomerInfo.preapprovalReferenceNumber
                        }
                      />
                      {payoffQuoteData.addressValidationResult.address
                        .addressLines[0] && (
                        <ShowNonEmptyTableRow
                          label={"Turn in address"}
                          data={
                            payoffQuoteData.addressValidationResult.address
                              .addressLines[0] +
                            ", " +
                            payoffQuoteData.addressValidationResult.address
                              .city +
                            ", " +
                            payoffQuoteData.addressValidationResult.address
                              .state +
                            ", " +
                            payoffQuoteData.addressValidationResult.address
                              .postalCode
                          }
                        />
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
              {payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                .footnote && (
                <Col sm={12}>
                  <div>
                    <h5>Additional Information</h5>
                    <div>
                      {
                        payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                          .footnote
                      }
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Tab>
          <Tab label="Payoff Instructions">{payoffInstComponent}</Tab>
        </Tabs>
        <Row>
          <Col sm={12} className={"margin-top-15-button hidden-print"}>
            <SubmitButton
              htmlId="SubmitButtonYes"
              buttonStyle="default"
              onClick={onDetailClose}
            >
              Close
            </SubmitButton>
          </Col>
        </Row>
      </Panel>
    </div>
  );
};

Detail.propTypes = {
  loading: PropTypes.bool,
  onDetailClose: PropTypes.func.isRequired,
  payoffQuoteData: PropTypes.object
};

export default Detail;
