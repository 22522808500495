import React from "react";
import PropTypes from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Card from "@cx/ui/Card";
import { getUSFormattedDate } from "../utils/dates";
import { currencyFormat } from "../utils/common";

const PayoffInstructionsRetail = ({ payoffQuoteData }) => {
  return (
    <div>
      <Row>
        <Col sm={10} md={8} lg={6}>
          <div className="margin-top-15-button">
            <Row>
              <Col xs={4} className={"payoff-inst-title-label"}>
                Model
              </Col>
              <Col xs={8} className={"payoff-inst-title-value"}>
                {
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                    .vehicleInfo.year
                }{" "}
                {
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                    .vehicleInfo.make
                }{" "}
                {
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                    .vehicleInfo.model
                }
              </Col>
            </Row>
            <Row>
              <Col xs={4} className={"payoff-inst-title-label"}>
                VIN
              </Col>
              <Col xs={8} className={"payoff-inst-title-value"}>
                {" "}
                {
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                    .vehicleInfo.vin
                }
              </Col>
            </Row>
            <Row>
              <Col xs={4} className={"payoff-inst-title-label"}>
                Account #
              </Col>
              <Col xs={8} className={"payoff-inst-title-value"}>
                {
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                    .customerInfo.account
                }
              </Col>
            </Row>
            <Row>
              <Col xs={4} className={"payoff-inst-title-label"}>
                Payoff obligation
              </Col>
              <Col xs={8} className={"payoff-inst-title-value"}>
                {" "}
                {currencyFormat(
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0].finance
                    .payoffAmount
                )}
                , valid through{" "}
                {getUSFormattedDate(
                  payoffQuoteData.inquiryResult.payoffQuoteResponse[0].finance
                    .goodUntilDate
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={4} className={"payoff-inst-title-label"}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Card htmlId="CardWithHeader" header="Payoff by Check">
            <Row>
              <Col sm={12} md={12} lg={12}>
                <p>
                  PAYOFFS RECEIVED BY CHECK WITHOUT BELOW LISTED DOCUMENTS WILL
                  RESULT IN TITLE DELAYS.
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={12} lg={12}>
                <ol>
                  <li>
                    Make check payable to <em>Honda Finance Exchange, Inc. </em>{" "}
                    <br />
                    <u>
                      Account number{" "}
                      {
                        payoffQuoteData.inquiryResult.payoffQuoteResponse[0]
                          .customerInfo.account
                      }{" "}
                      must be on the check.
                    </u>
                  </li>
                  <li>
                    Print this page and provide title release instructions:
                  </li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col
                xsOffset={2}
                xs={10}
                smOffset={2}
                sm={10}
                mdOffset={2}
                md={10}
                lgOffset={4}
                lg={8}
              >
                <Row>
                  <Col xs={4} className={"payoff-inst-title-label"}>
                    Name:
                  </Col>
                  <Col xs={8} className={"payoff-inst-title-value"}>
                    <div className={"blank-line"}>&nbsp;</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className={"payoff-inst-title-label"}>
                    Address:
                  </Col>
                  <Col xs={8} className={"payoff-inst-title-value"}>
                    <div className={"blank-line"}>&nbsp;</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className={"payoff-inst-title-label"}>
                    &nbsp;
                  </Col>
                  <Col xs={8} className={"payoff-inst-title-value"}>
                    <div className={"blank-line"}>&nbsp;</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={4} className={"payoff-inst-title-label"}>
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <strong>
                  Mail this printed page (with title release instructions) and
                  payoff check to:
                </strong>
                <Row>
                  <Col xs={5} sm={4}>
                    <u>Mailing address (USPS)</u>
                    <address>
                      Honda Finance Exchange, Inc.
                      <br />
                      P.O. Box 70252
                      <br />
                      Philadelphia, PA 19176-0252
                    </address>
                  </Col>
                  <Col xs={7} sm={4}>
                    {" "}
                    <u>Physical address (overnight mail)</u>
                    <address>
                      Honda Finance Exchange, Inc.
                      <br />
                      c/o Lockbox #70252
                      <br />
                      101 N. Independence Mall East
                      <br />
                      Philadelphia, PA 19106
                    </address>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

PayoffInstructionsRetail.propTypes = {
  payoffQuoteData: PropTypes.object
};

export default PayoffInstructionsRetail;
