// This is the app's initial state. Note that reducers each handle a slice of this state.
// Composing all initial state here gives us a clear spot of reference that displays the shape
// of our entire store.
import config from "../config";

const LENDER_ID_VAL = `${config.LENDER_ID}`;
const DEALER_ID_VAL = `${config.DEALER_DTC_ID}`;
export default {
  ajaxCallsInProgress: 0,
  legalsAccepted: false,
  isSearchDone: false,
  captchaToken: "",
  jwtToken: "",
  captchaFailed: false,
  dealerId: DEALER_ID_VAL,
  showAddressValidatorModel: false,
  inquiryData: {
    partner: LENDER_ID_VAL,
    financeType: "Retail",
    searchType: "VIN",
    ssnLast4: "",
    acc: "",
    vin: "",
    dealerName: "",
    addressStreet: "",
    addressCity: "",
    addressPostal: "",
    addressState: "",
    turnInPolice: false,
    turnInUnincorporated: false,
    agreement: [],
    turnInAgreement: []
  },
  inquiryResult: {},
  addressValidationResult: {
    address: {
      addressLines: [],
      city: "",
      county: "",
      state: "",
      postalCode: ""
    }
  },
  inquiryResultError: false,
  errors: {}
};
