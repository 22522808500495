import { combineReducers } from "redux";
import payoffQuote from "./payoffQuoteReducer";
import ajaxCallsInProgress from "./loadingStatusReducer";

// Note that we're using a shorter name above when importing reducers.
// This keeps the calls to these reducers in our container components
// shorter and more natural. Using ES6 short-hand properties below
// (Don't have to repeat yourself on the right-hand side when the prop
// and value match)
const rootReducer = combineReducers({
  payoffQuote,
  ajaxCallsInProgress
});

export default rootReducer;
